$(document).ready(function() {
    //$('.lazy').show().lazy();
    $('.video a').magnificPopup({
        type: 'iframe',
        gallery: {
            enabled: true
        }
    });
    /*
    $('.lazy').lazy({
            effect: "fadeIn",
            effectTime: 500,
            threshold: 0
    });*/
    const player = new Plyr(document.getElementById('player'),{
        controls:['play-large', 'play', 'fullscreen'],
        loop: true
    });
    //const player = new Plyr('#player');

    /* START menu for tablets and mobiles */
    $("#head a.menu").click(function() {
        if($('#head ul').hasClass('active')){
            $('#head ul').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-bars').removeClass('icon-close');
        }else{
            $('#head ul').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-bars').addClass('icon-close');
        }
    });

    $('.idLightSlider').lightSlider({
        gallery: true,
        item: 1,
        autoWidth: false,
        loop: true,
        slideMargin: 0,
        thumbItem: 5,
        enableTouch:true,
        enableDrag:true,
        freeMove:true
    });

    $(".jsLink").click(function(){
         window.location=$(this).find("a").attr("href");
         return false;
    });

});
